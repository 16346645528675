"use client";

import { type TsRates } from "@/types/shipmentOrder.type";
import { handleRequest } from "./handle_request";

export async function getShipmentOrders(status="", pageIndex = 0, pageSize = 10, update: (session: any) => void) {
  const response = await handleRequest(
    `/admin/shipment_orders?status=${status}&page_id=${pageIndex +1}&page_size=${pageSize}`, 
    'GET',
    update
  );
  
  return response.data || {};
}
export async function getShipmentOrder(id: string, update: (session: any) => void) {
  const response = await handleRequest(`/admin/shipment_orders/${id}`, 'GET', update);
  
  return response.data || {};
}

export async function getShippingRatesForShipmentOrder(id: string, update: (session: any) => void) {
  const response = await handleRequest(`/admin/shipment_orders/${id}/shipping_rates`, 'GET', update);
  
  return response.data || [];
}

export async function getShipmentOrderStatuses(update: (session: any) => void) {
  const response = await handleRequest('/admin/shipment_orders/statuses', 'GET', update);

  return response.data || [];
}

// export async function payForShipmentOrder(shipmentOrderId: string, amount: number, token: string) {
//   const response = await handleRequest(`/shipment_orders/${shipmentOrderId}/payment`, 'POST', { 
//     amount_cents: amount,
//     currency: "thb",
//     token: token,
//     return_uri: `${process.env.NEXT_PUBLIC_URL}/shipment_orders/${shipmentOrderId}/complete`
//   });

// 
// 
// return data;
// }

// export async function getShipmentOrderPayment(shipmentOrderId: string) {
//   const response = await handleRequest(`/shipment_orders/${shipmentOrderId}/payment`, 'GET');
// 
// 
// return data;
// }

// export async function startShipment(shipmentOrderId: string) {
//   const response = await handleRequest(`/shipment_orders/${shipmentOrderId}/start`, 'GET');
// 
// 
// return data;
// }

// export async function createShipmentOrder(shipmentOrder: any) {
//   const response = await handleRequest('/shipment_orders', 'POST', shipmentOrder)
// 
// 
// return data || {};
// }

export async function updateShipmentOrder(shipmentOrderId: string, shipmentOrder: any, update: (session: any) => void) {
  const response = await handleRequest(`/admin/shipment_orders/${shipmentOrderId}`, 'PUT', update, shipmentOrder)
  
  return response.data || {};
}

export async function getRates(rates: TsRates, update: (session: any) => void) {
  const response = await handleRequest(`/admin/get_shipping_rates`, 'POST', update, rates)
  
  return response.data || [];
}

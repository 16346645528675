'use client';

import { TAccount } from "@/types/account.type";
import { handleRequest } from "./handle_request";

export async function fetchLineProfile(accountId: string, update: (session: any) => void) {
  const response = await handleRequest(`/admin/accounts/${accountId}/line_profile`, 'GET', update);

  return response.data || {};
}

export async function updateAccount(account: TAccount, update: (session: any) => void) {
  const response = await handleRequest(`/admin/accounts/${account.id}`, 'PUT', update, account);

  return response.data || {};
}

export async function searchAccounts(queryString: string, update: (session: any) => void) {
  const response = await handleRequest(`/admin/accounts/search?query=${queryString}`, 'GET', update);

  return response.data || [];
}
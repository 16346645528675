import { usePathname, useSearchParams } from "next/navigation";
import purePathnameUtil from "./purePathname.util";

const getCurrentPath = () => {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const query = searchParams?.toString();

  return query ? `${purePathnameUtil(pathName)}?${query}` : purePathnameUtil(pathName);
}

export default getCurrentPath;
'use client';

import { handleRequest } from "./handle_request";

export async function getParcels(pageIndex = 0, pageSize = 10, update: (session: any) => void) {
  const response = await handleRequest(`/admin/parcels?page_id=${pageIndex + 1}&page_size=${pageSize}`, 'GET', update);
  
  return response.data || {};
}

export async function createParcel(parcel: any, update: (session: any) => void) {
  const response = await handleRequest('/admin/parcels', 'POST', update, parcel)
  
  return response.data || {};
}

export async function getParcel(id: any, update: (session: any) => void) {
  const response = await handleRequest(`/admin/parcels/${id}`, 'GET', update)
  
  return response.data || {};
}

export async function updateParcel(id: any, parcel: any, update: (session: any) => void) {
  const response = await handleRequest(`/admin/parcels/${id}`, 'PUT', update, parcel)
  
  return response.data || {};
}

export async function getParcelUrlForUpload(filename: string, update: (session: any) => void) {
  const response = await handleRequest(`/admin/parcels/presigned_url`, 'POST', update, {key: filename})
  
  return response.data || "";
}
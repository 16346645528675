import React, { useEffect, useState, Suspense } from "react";
import { appPages } from "@/config/pages.config";
import { useRouter } from "next/navigation";
import Aside, {
  AsideBody,
  AsideFooter,
  AsideHead,
} from "../../../components/layouts/Aside/Aside";
import LogoAndAsideTogglePart from "./_parts/LogoAndAsideToggle.part";
import DarkModeSwitcherPart from "./_parts/DarkModeSwitcher.part";
import Nav, {
  NavButton,
  NavCollapse,
  NavItem,
  NavSeparator,
  NavTitle,
  NavUser,
} from "../../../components/layouts/Navigation/Nav";
// import Badge from "../../../components/ui/Badge";
// import UserTemplate from "../User/User.template";
// import usersDb from "../../../mocks/db/users.db";
import { TShipmentOrderStatusCount } from "@/types/shipmentOrder.type";
import { useSession } from "next-auth/react";
import { getShipmentOrderStatuses } from "@/actions";

const DefaultAsideTemplate = () => {
  const router = useRouter();
  const { update } = useSession();

  const [statusCounts, setStatusCounts] = useState([] as TShipmentOrderStatusCount[]);

  useEffect(() => {
    const fetchShipmentOrderStatuses = async () => {
      const shipmentOrderStatuses = await getShipmentOrderStatuses(update);
      setStatusCounts(shipmentOrderStatuses);
    }
    fetchShipmentOrderStatuses();
  }, []);

  const StatusCount = (status: Number) => {
    const foundItem = statusCounts.find((item) => item.status === status);
    return foundItem ? foundItem.count : 0;
  }

  const pendingMenu = {
    ...appPages.shipmentOrdersPage.subPages.pendingPage,
    text: `Pending (${StatusCount(5)})`
  }

  const unpaidMenu = {
    ...appPages.shipmentOrdersPage.subPages.unpaidPage,
    text: `Unpaid (${StatusCount(0)})`
  }

  const picpackMenu = {
    ...appPages.shipmentOrdersPage.subPages.pickpackPage,
    text: `Pick Pack (${StatusCount(1)})`
  }

  const inTransitMenu = {
    ...appPages.shipmentOrdersPage.subPages.inTransitPage,
    text: `In Transit (${StatusCount(2)})`
  }

  const deliveredMenu = {
    ...appPages.shipmentOrdersPage.subPages.deliveredPage,
    text: `Delivered (${StatusCount(3)})`
  }

  const cancelledMenu = {
    ...appPages.shipmentOrdersPage.subPages.cancelledPage,
    text: `Cancelled (${StatusCount(4)})`
  }


  return (
    <Aside>
      <AsideHead>
        <LogoAndAsideTogglePart />
      </AsideHead>
      <AsideBody>
        <Suspense>
          <Nav>
            <NavItem {...appPages.parcelsPage.subPages.listPage} />
            <NavItem {...appPages.warehousesPage.subPages.listPage} />
            <NavCollapse
              text={appPages.shipmentOrdersPage.text}
              to={appPages.shipmentOrdersPage.to}
              icon={appPages.shipmentOrdersPage.icon}>
              <NavItem {...appPages.shipmentOrdersPage.subPages.listPage} />
              <NavItem {...pendingMenu} />
              <NavItem {...unpaidMenu} />
              <NavItem {...picpackMenu} />
              <NavItem {...inTransitMenu} />
              <NavItem {...deliveredMenu} />
              <NavItem {...cancelledMenu} />
            </NavCollapse>
            <NavItem {...appPages.customersPage.subPages.listPage} />
          </Nav>
        </Suspense>
      </AsideBody>
      <AsideFooter>
        {/* <UserTemplate /> */}
        <DarkModeSwitcherPart />
      </AsideFooter>
    </Aside>
  );
};

export default DefaultAsideTemplate;

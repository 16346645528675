'use client';

import { handleRequest } from "./handle_request";

export async function getWarehouses(update: (session: any) => void) {
  const response = await handleRequest('/admin/warehouses?page_id=1&page_size=10', 'GET', update);
  
  return response.data || [];
}

export async function createWarehouse(warehouse: any, update: (session: any) => void) {
  const response = await handleRequest('/admin/warehouses', 'POST', update, warehouse)
  
  return response.data || {};
}

export async function getWarehouse(id: any, update: (session: any) => void) {
  const response = await handleRequest(`/admin/warehouses/${id}`, 'GET', update);
  
  return response.data || {};
}

export async function updateWarehouse(id: any, warehouse: any, update: (session: any) => void) {
  const response = await handleRequest(`/admin/warehouses/${id}`, 'PUT', update, warehouse)
  
  return response.data || {};
}